mtl.alloy.factory['mobile-footer'] = (function($) {
    'use strict';

	return function(element, options) {

		var title = $(element).find('.footer-column-title');

		$(title).click(function(){
			
			var width = $(window).width();

			if(width <= 950)
			{
				var content = $(element).find('.footer-column-content');
				var button = $(element).find('.footer-column-button');

				if($(content).is(":visible"))
	    		{
	    			$(content).slideUp();
	    			$(button).text('+');
	    		}
	    		else
	    		{
					$(content).slideDown({
					  start: function () {
					    $(this).css({
					      display: "flex"
					    })
					  }
					});
	    			$(button).text('-');
	    		}
			}
		});
	};
}(jQuery));