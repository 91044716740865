mtl.alloy.factory['filter-slide'] = (function($) {
    'use strict';

	return function(element, options) {
		var title = $(element).find('.filter-top-category-title');
		var childCategories = $(element).find('.filter-child-categories');
		var button = $(element).find('.filter-button');

		$(title).click(function(){
		    if($(childCategories).is(":visible"))
    		{
    			$(childCategories).slideUp();
    			$(button).text('+');
    		}
    		else
    		{
				$(childCategories).slideDown({
				  start: function () {
				    $(this).css({
				      display: "flex"
				    })
				  }
				});
    			$(button).text('-');
    		}
		});
	};
}(jQuery));