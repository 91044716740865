mtl.alloy.factory['gallery-slider'] = (function($) {
    'use strict';

	return function(element, options) {
		
		var reviewId = $(element).data("review-id");
    	var modal = "#"+reviewId;
    	var gallerySlider = "."+reviewId;

		$(element).click(function(event){

			$('html, body').addClass("noscroll"); 
	    	if($(modal).is(":hidden"))
	    	{
		    	$(modal).css('display', 'flex'); 
				$(gallerySlider).slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: false,
					autoplaySpeed: 5000,
					adaptiveHeight: true,
					nextArrow:'.next',
					prevArrow:'.prev',
					speed:500,
				});
	    	}
	    	else{
			   if($(event.target).find(gallerySlider).length && !$(event.target).is(gallerySlider)) {
			    	$(modal).hide();
					$(gallerySlider).slick('unslick');
					$('html, body').removeClass("noscroll");
			   }  
	    	}
		});

		$('.gallery-close').click(function(e){
			$(gallerySlider).trigger('unslick');
	    	$(modal).hide();
			$('html, body').removeClass("noscroll");
	    	e.stopPropagation(); //stop the event from bubbling to the parent
		});

		$(document).on('keydown', function(e) {
	       if (event.key == "Escape") {
				$(gallerySlider).trigger('unslick');
		    	$(modal).hide();
				$('html, body').removeClass("noscroll");
		    	e.stopPropagation(); //stop the event from bubbling to the parent
	       }
	   });

	};
}(jQuery));