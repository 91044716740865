mtl.alloy.factory['mobile-subcategory'] = (function($) {
    'use strict';

	return function(element, options) {

		$(element).click(function(){
			var $menuId = $(element).data("menu-id");
	    	var $menuElement = "#"+$menuId;

    		if($($menuElement).is(":visible"))
    		{
	    		$($menuElement).hide();
	    		$('.offset-canvas-category').show();
    		}
    		else{
    			$($menuElement).css('display', 'flex');
	    		$('.offset-canvas-category').hide();
    		}
		});
	};
}(jQuery));