mtl.alloy.factory['same-address'] = (function($) {
    'use strict';

	return function(element, options) {
		$(element).click(function(){
			if(this.checked) {
				$('#checkout_single_step_deliveryAddress_postcode').val($('#checkout_single_step_billingAddress_postcode').val());
				$('#checkout_single_step_deliveryAddress_businessName').val($('#checkout_single_step_billingAddress_businessName').val());
				$('#checkout_single_step_deliveryAddress_firstName').val($('#checkout_single_step_firstName').val());
				$('#checkout_single_step_deliveryAddress_lastName').val($('#checkout_single_step_lastName').val());
				$('#checkout_single_step_deliveryAddress_lastName').val($('#checkout_single_step_lastName').val());
				$('#checkout_single_step_deliveryAddress_tel').val($('#checkout_single_step_tel').val());
				$('#checkout_single_step_deliveryAddress_line1').val($('#checkout_single_step_billingAddress_line1').val());
				$('#checkout_single_step_deliveryAddress_line2').val($('#checkout_single_step_billingAddress_line2').val());
				$('#checkout_single_step_deliveryAddress_county').val($('#checkout_single_step_billingAddress_county').val());
				$('#checkout_single_step_deliveryAddress_town').val($('#checkout_single_step_billingAddress_town').val());
				$('#checkout_single_step_deliveryAddress_country').val($('#checkout_single_step_billingAddress_country').val());
			}
			else{
				$('#checkout_single_step_deliveryAddress_postcode').val('')
				$('#checkout_single_step_deliveryAddress_businessName').val('')
				$('#checkout_single_step_deliveryAddress_firstName').val('')
				$('#checkout_single_step_deliveryAddress_lastName').val('')
				$('#checkout_single_step_deliveryAddress_lastName').val('')
				$('#checkout_single_step_deliveryAddress_tel').val('')
				$('#checkout_single_step_deliveryAddress_line1').val('')
				$('#checkout_single_step_deliveryAddress_line2').val('')
				$('#checkout_single_step_deliveryAddress_county').val('')
				$('#checkout_single_step_deliveryAddress_town').val('')
				$('#checkout_single_step_deliveryAddress_country').val('')
			}
		});
	};
}(jQuery));