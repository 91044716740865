mtl.alloy.factory['calculator'] = (function($) {
    'use strict';

	return function(element, options) {
		
		$(document).ready(function(){
			var first_area = 'rectangle';
			var area = first_area;
			var total_area = 0;

			$('.calculator-area').click(function() {
			    var this_area = $(this).children('label').text().toLowerCase();
			    if(this_area != area)
			    {
			        $('.calculator-area').removeClass('current');
			        $(this).addClass('current');
			        $('#'+ area +'_dimensions').slideUp('fast', function() {
			            $('#'+ this_area +'_dimensions').slideDown('fast');
			        });
			        area = this_area;
			    }
			});

			$('button.add').click(function(e) {
			    e.preventDefault();
			    
			    // Switch the kind:
			    switch(area)
			    {
			        case 'rectangle':
			            var width = $.trim($('#rectangle_width').val());
			            var length = $.trim($('#rectangle_length').val());
			            var measure = $('#rectangle_measure').val();
			            
			            if(width == '' || length == '')
			            {
			                alert('Please enter the area dimensions.');
			                $('#rectangle_width').focus();
			            }
			            else
			            {
			                var area_size = (measure == 'f') ? ((width * 0.3048) * (length * 0.3048)) : width * length;
			                addAreaToProject(area_size, 'square');
			            }
			            break;
			        
			        case 'circle':
			            var diameter = $.trim($('#circle_diamter').val());
			            var measure = $('#circle_measure').val();
			            
			            if(diameter == '')
			            {
			                alert('Please enter the diameter of your area.');
			                $('#circle_diameter').focus();
			            }
			            else
			            {
			                var area_size = (measure == 'f') ? (0.25 * Math.PI * ((diameter * 0.3048) * (diameter * 0.3048))) : 0.25 * Math.PI * (diameter * diameter);
			                addAreaToProject(area_size, 'circle');
			            }
			            break;
			        
			        case 'elipse':
			            var width = parseFloat($.trim($('#elipse_width').val()));
			            var length = parseFloat($.trim($('#elipse_length').val()));
			            var measure = $('#elipse_measure').val();
			            
			            if(isNaN(width) || isNaN(length))
			            {
			                alert('Please enter the dimensions of your area.')
			                $('#elipse_width').focus();
			            }
			            else
			            {
			                var area_size = Math.PI * (width / 2) * (length / 2);
			                var area_size = (measure == 'f') ? Math.PI * ((width * 0.3048) / 2) * ((length * 0.3048) / 2) : Math.PI * (width / 2) * (length / 2);
			                addAreaToProject(area_size, 'elipse');
			            }
			            
			            break;
			        
			        case 'triangle':
			            var a = parseFloat($.trim($('#triangle_a').val()));
			            var b = parseFloat($.trim($('#triangle_b').val()));
			            var c = parseFloat($.trim($('#triangle_c').val()));
			            var measure = $('#triangle_measure').val();
			            
			            if(isNaN(a) || isNaN(b) || isNaN(c))
			            {
			                alert('Please enter the dimensions of your area.');
			                $('#triangle_a').focus();
			            }
			            else
			            {
			                var p = (a + b + c) / 2;
			                var heron = p * (p - a) * (p - b) * (p - c);
			                var area_size = Math.sqrt(heron);               
			                if(measure == 'f') { area_size *= 0.3048; }
			                addAreaToProject(area_size, 'triangle');
			            }
			            break;
			    }
			});

			// Function to add a measurement to the total area:
			function addAreaToProject(total, image)
			{
			    // Build the template:
			    var li = $('<li data-area="'+ total +'">\
						    	<div class="area-wrapper">\
				                    <img src="images/placeholders/'+ image +'.png" />\
				                    <span class="area">Total Area: '+ total.round(2) +' m<sup>2</sup></span>\
			                    </div>\
			                    <button href="#" title="Remove" class="remove-area">&times;</button>\
			                </li>');
			    
			    li.appendTo($('ul.areas'));
			    total_area += total;
			    run_calc();
			}

			// When they click on the close button, remove it:
			$(document).on('click','.remove-area',function(e) {
			    var the_area = parseFloat($(this).parent('li').attr('data-area'));
			    total_area -= the_area;
			    $(this).parent('li').remove();
			    run_calc();
			});

			// Function to round to a number of decimal places:
			Number.prototype.round = function(sig_figs)
			{
			    return Math.round(this * Math.pow(10, sig_figs)) / Math.pow(10, sig_figs);
			}

			$('#seed-type').change(run_calc);

			// Function to run the calculations:
			function run_calc()
			{
			    var seed_rate = $('#seed-type').val(),
			        calc_area = $('#calculation');
			        
			    if(total_area == 0 || seed_rate == '')
			    {
			        calc_area.html('Please enter details of your area and seed type to calculate').removeClass('active');
			    }
			    else
			    {
			        var seed_type = (seed_rate < 10) ? 'wildflower' : 'grass',
			            grams_required = seed_rate * total_area,
			            kilos_required = Math.ceil(grams_required / 1000),
			            html_string = "You require " + kilos_required + " kilograms of " + seed_type + " seed to cover " + Math.ceil(total_area) + "m<sup>2</sup>",
			            link_title = '',
			            link_url = '#';
			            
			        switch(seed_type)
			        {
			            case 'wildflower':
			                link_title = 'Buy Wildflower Seed Online Now!';
			                link_url = '../products/wildflowers-seed';
			                break;
			            case 'grass':
			                link_title = 'Buy Grass and Lawn Seed Online Now!';
			                link_url = '../products/Grass-Seeds';
			                break;
			        }
			        
			        html_string += '<br /><br /><a class="buy-seed-button" href="'+ link_url +'" title="'+ link_title + '">Buy your seed</a>';
			        calc_area.html(html_string);
			        calc_area.addClass('active');
			        
			    }
			}
		});			
	};
}(jQuery));