(function ($) {
    'use strict';

    // Initiate alloy process on document.ready
    $(document).ready(function () {

        // Process alloy directives when content is opened in a modal
        app.modal.on('afterParse', function (event) {
            mtl.alloy.process(event.content);
        });

        // Process alloy directives
        mtl.alloy.process(document.body);

        // Polyfill external <use> support for IE
        svg4everybody();
    });

}(jQuery));
