mtl.alloy.factory['item-slider'] = (function($) {
    'use strict';

	return function(element, options) {

		function sliderLoader(slider, slideIndex)
		{
			var slide = slider.find('[data-slick-index=' + slideIndex + ']');
			var loading = slide.find('.image-loading');
			if (loading.length > 0 && !loading.hasClass('loading')) {
				loading.addClass('loading');
				loading.show();
				var image = $('<img loading="lazy">');
				image.on('load', function() {
					image.css({
						position: '',
						top: '',
						left: '',
						visibility: '',
					});
					loading.remove();
					if (slider.hasClass('slick-initialized')) {
						slider.slick('refresh');
						image.fadeOut(0).fadeIn(200);
					}
				});
				image.css({
					position: 'absolute',
					top: 0,
					left: 0,
					visibility: 'hidden',
				});
				image.attr('alt', loading.attr('data-alt'));
				image.attr('width', loading.attr('data-width'));
				image.attr('height', loading.attr('data-height'));
				image.attr('class', loading.attr('class'));
				image.removeClass('image-loading');
				image.insertAfter(loading);
				image.attr('src', loading.attr('data-src'));
			}
		}

		$(document).ready(function() {

			$('.slider-modal').slick({
			  	slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				autoplaySpeed: 5000,
				adaptiveHeight: true,
				nextArrow:'.next',
				prevArrow:'.prev',
				speed:500,
				fade: true,
				draggable: true,
			  	asNavFor: '.slider-gallery'
			}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				sliderLoader($(this), nextSlide);
			});

			$('.slider-gallery').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				fade: true,
				draggable: true,
				asNavFor: '.slider-modal',
				nextArrow:'<a class="next"><svg class="icon icon--custom icon--custom__gallery-next" width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.7211 9.51105C10.7523 9.53442 10.7882 9.54521 10.8146 9.57277C10.942 9.70762 11.0007 9.87602 10.9974 10.0468C11.0002 10.134 10.9855 10.2209 10.9541 10.3022C10.9227 10.3836 10.8753 10.4579 10.8146 10.5206C10.7879 10.5475 10.752 10.5634 10.7208 10.5844L1.07896 19.8109C1.02089 19.8706 0.951463 19.9179 0.87478 19.9503C0.798098 19.9827 0.715711 19.9993 0.632482 19.9993C0.549253 19.9993 0.466866 19.9827 0.390184 19.9503C0.313501 19.9179 0.244075 19.8706 0.186002 19.8109C0.0670046 19.688 0.000474989 19.5236 0.000474989 19.3525C0.000474989 19.1814 0.0670046 19.017 0.186002 18.894L9.45839 10.0462V9.83527L0.186002 1.09236C0.0670182 0.971695 0.000312805 0.809045 0.000312805 0.639585C0.000312805 0.470125 0.0670182 0.307475 0.186002 0.186813C0.244223 0.12745 0.313696 0.0802935 0.390359 0.0481016C0.467022 0.0159096 0.549334 -0.000671387 0.632482 -0.000671387C0.71563 -0.000671387 0.797942 0.0159096 0.874605 0.0481016C0.951268 0.0802935 1.02074 0.12745 1.07896 0.186813L10.7211 9.30758C10.7523 9.32766 10.7882 9.34054 10.8146 9.36841C10.942 9.49786 11.0007 9.66746 10.9974 9.83587C11.0002 9.92242 10.9854 10.0086 10.954 10.0893C10.9226 10.17 10.8752 10.2435 10.8146 10.3054C10.7879 10.3342 10.752 10.3468 10.7208 10.3665" fill="white"/></svg></a>',
				prevArrow:'<a class="prev"><svg class="icon icon--custom icon--custom__gallery-prev" width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.275756 10.4877C0.244592 10.4644 0.208635 10.4536 0.182266 10.426C0.121972 10.363 0.074751 10.2886 0.0433279 10.2073C0.0119049 10.1259 -0.00309891 10.0391 -0.000817938 9.95196C-0.00367655 9.86472 0.0110314 9.77781 0.0424317 9.69637C0.0738319 9.61494 0.121284 9.54065 0.181966 9.47792C0.208335 9.45095 0.244293 9.43537 0.275456 9.41439L9.91747 0.187683C9.97555 0.128063 10.045 0.0806773 10.1217 0.0483223C10.1983 0.0159673 10.2807 -0.000701904 10.3639 -0.000701904C10.4472 -0.000701904 10.5296 0.0159673 10.6062 0.0483223C10.6829 0.0806773 10.7523 0.128063 10.8104 0.187683C10.9294 0.310634 10.9959 0.475036 10.9959 0.646142C10.9959 0.817247 10.9294 0.981649 10.8104 1.1046L1.53847 9.95226V10.1632L10.8104 18.906C11.0579 19.1568 11.0579 19.5592 10.8104 19.8118C10.7522 19.8712 10.6827 19.9183 10.6061 19.9505C10.5294 19.9827 10.4471 19.9993 10.3639 19.9993C10.2808 19.9993 10.1985 19.9827 10.1218 19.9505C10.0452 19.9183 9.97569 19.8712 9.91747 19.8118L0.275756 10.6909C0.244592 10.6711 0.208635 10.6579 0.182266 10.6304C0.121931 10.5687 0.0746455 10.4956 0.0432389 10.4153C0.0118323 10.3349 -0.00305025 10.2491 -0.000518292 10.1629C-0.00334144 10.0763 0.011398 9.99004 0.0428118 9.90929C0.0742256 9.82854 0.121663 9.75499 0.182266 9.69306C0.208635 9.6643 0.244592 9.65171 0.275756 9.63193" fill="white"/></svg></a>',
				responsive: [
			    {
			      breakpoint: 950,
			      settings: {
			        dots: false
			      }
			    }
			  ]
			}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				sliderLoader($(this), nextSlide);
			});

			$('.slider-nav').slick({
				infinite: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				asNavFor: $('.slider-gallery, .slider-modal'),
				dots: false,
				arrows: true,
				draggable: false,
				centerMode: false,
				focusOnSelect: true,
				mobileFirst: true,
				variableWidth: true,
				nextArrow:'<a class="next"><svg class="icon icon--custom icon--custom__gallery-next" width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.7211 9.51105C10.7523 9.53442 10.7882 9.54521 10.8146 9.57277C10.942 9.70762 11.0007 9.87602 10.9974 10.0468C11.0002 10.134 10.9855 10.2209 10.9541 10.3022C10.9227 10.3836 10.8753 10.4579 10.8146 10.5206C10.7879 10.5475 10.752 10.5634 10.7208 10.5844L1.07896 19.8109C1.02089 19.8706 0.951463 19.9179 0.87478 19.9503C0.798098 19.9827 0.715711 19.9993 0.632482 19.9993C0.549253 19.9993 0.466866 19.9827 0.390184 19.9503C0.313501 19.9179 0.244075 19.8706 0.186002 19.8109C0.0670046 19.688 0.000474989 19.5236 0.000474989 19.3525C0.000474989 19.1814 0.0670046 19.017 0.186002 18.894L9.45839 10.0462V9.83527L0.186002 1.09236C0.0670182 0.971695 0.000312805 0.809045 0.000312805 0.639585C0.000312805 0.470125 0.0670182 0.307475 0.186002 0.186813C0.244223 0.12745 0.313696 0.0802935 0.390359 0.0481016C0.467022 0.0159096 0.549334 -0.000671387 0.632482 -0.000671387C0.71563 -0.000671387 0.797942 0.0159096 0.874605 0.0481016C0.951268 0.0802935 1.02074 0.12745 1.07896 0.186813L10.7211 9.30758C10.7523 9.32766 10.7882 9.34054 10.8146 9.36841C10.942 9.49786 11.0007 9.66746 10.9974 9.83587C11.0002 9.92242 10.9854 10.0086 10.954 10.0893C10.9226 10.17 10.8752 10.2435 10.8146 10.3054C10.7879 10.3342 10.752 10.3468 10.7208 10.3665" fill="white"/></svg></a>',
				prevArrow:'<a class="prev"><svg class="icon icon--custom icon--custom__gallery-prev" width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.275756 10.4877C0.244592 10.4644 0.208635 10.4536 0.182266 10.426C0.121972 10.363 0.074751 10.2886 0.0433279 10.2073C0.0119049 10.1259 -0.00309891 10.0391 -0.000817938 9.95196C-0.00367655 9.86472 0.0110314 9.77781 0.0424317 9.69637C0.0738319 9.61494 0.121284 9.54065 0.181966 9.47792C0.208335 9.45095 0.244293 9.43537 0.275456 9.41439L9.91747 0.187683C9.97555 0.128063 10.045 0.0806773 10.1217 0.0483223C10.1983 0.0159673 10.2807 -0.000701904 10.3639 -0.000701904C10.4472 -0.000701904 10.5296 0.0159673 10.6062 0.0483223C10.6829 0.0806773 10.7523 0.128063 10.8104 0.187683C10.9294 0.310634 10.9959 0.475036 10.9959 0.646142C10.9959 0.817247 10.9294 0.981649 10.8104 1.1046L1.53847 9.95226V10.1632L10.8104 18.906C11.0579 19.1568 11.0579 19.5592 10.8104 19.8118C10.7522 19.8712 10.6827 19.9183 10.6061 19.9505C10.5294 19.9827 10.4471 19.9993 10.3639 19.9993C10.2808 19.9993 10.1985 19.9827 10.1218 19.9505C10.0452 19.9183 9.97569 19.8712 9.91747 19.8118L0.275756 10.6909C0.244592 10.6711 0.208635 10.6579 0.182266 10.6304C0.121931 10.5687 0.0746455 10.4956 0.0432389 10.4153C0.0118323 10.3349 -0.00305025 10.2491 -0.000518292 10.1629C-0.00334144 10.0763 0.011398 9.99004 0.0428118 9.90929C0.0742256 9.82854 0.121663 9.75499 0.182266 9.69306C0.208635 9.6643 0.244592 9.65171 0.275756 9.63193" fill="white"/></svg></a>'
			});

			$(".slider-gallery").click(function(){
				var width = $(window).width();

				if(width >= 950)
				{
					var modal = $('.slider-modal');
					//sliderLoader(modal, modal.find('.slick-slide.slick-current').attr('data-slick-index'));
					modal.find('[data-slick-index]').each(function() {
						var slide = $(this);
						sliderLoader(modal, slide.attr('data-slick-index'));
					});
					$('.gallery-modal').toggle();
					modal.toggle();
					modal.get(0).slick.setPosition()
				}
			});

			$(element).click(function(event){
				if($('.gallery-modal').is(":visible"))
				{
					if($(event.target).find('.slider-modal').length && !$(event.target).is('.slider-modal')) {
				    	$('.gallery-modal').toggle();
						$('.slider-modal').toggle();
				   	}  

				}
			});

			$('.gallery-close').click(function(e){
					$('.gallery-modal').toggle();
					$('.slider-modal').toggle();
		    	e.stopPropagation(); //stop the event from bubbling to the parent
			});

			$(document).on('keydown', function(e) {
		       if (event.key == "Escape") {
		            $('.gallery-modal').toggle();
					$('.slider-modal').toggle();
			    	e.stopPropagation(); //stop the event from bubbling to the parent
		       }
		    });

			$('.item-list-gallery, .item-grid-gallery').each(function() {
				var slider = $(this);
				slider.find('img').on('load', function() {
					var slider = $(this).closest('.item-list-gallery, .item-grid-gallery');
					if (slider.hasClass('slick-initialized')) {
						slider.slick('refresh');
					}
				});
				slider.not('.slick-initialized').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: false,
					autoplaySpeed: 5000,
					adaptiveHeight: false,
					nextArrow:'<a class="next"><svg class="icon icon--custom icon--custom__gallery-next" width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.7211 9.51105C10.7523 9.53442 10.7882 9.54521 10.8146 9.57277C10.942 9.70762 11.0007 9.87602 10.9974 10.0468C11.0002 10.134 10.9855 10.2209 10.9541 10.3022C10.9227 10.3836 10.8753 10.4579 10.8146 10.5206C10.7879 10.5475 10.752 10.5634 10.7208 10.5844L1.07896 19.8109C1.02089 19.8706 0.951463 19.9179 0.87478 19.9503C0.798098 19.9827 0.715711 19.9993 0.632482 19.9993C0.549253 19.9993 0.466866 19.9827 0.390184 19.9503C0.313501 19.9179 0.244075 19.8706 0.186002 19.8109C0.0670046 19.688 0.000474989 19.5236 0.000474989 19.3525C0.000474989 19.1814 0.0670046 19.017 0.186002 18.894L9.45839 10.0462V9.83527L0.186002 1.09236C0.0670182 0.971695 0.000312805 0.809045 0.000312805 0.639585C0.000312805 0.470125 0.0670182 0.307475 0.186002 0.186813C0.244223 0.12745 0.313696 0.0802935 0.390359 0.0481016C0.467022 0.0159096 0.549334 -0.000671387 0.632482 -0.000671387C0.71563 -0.000671387 0.797942 0.0159096 0.874605 0.0481016C0.951268 0.0802935 1.02074 0.12745 1.07896 0.186813L10.7211 9.30758C10.7523 9.32766 10.7882 9.34054 10.8146 9.36841C10.942 9.49786 11.0007 9.66746 10.9974 9.83587C11.0002 9.92242 10.9854 10.0086 10.954 10.0893C10.9226 10.17 10.8752 10.2435 10.8146 10.3054C10.7879 10.3342 10.752 10.3468 10.7208 10.3665" fill="white"/></svg></a>',
					prevArrow:'<a class="prev"><svg class="icon icon--custom icon--custom__gallery-prev" width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.275756 10.4877C0.244592 10.4644 0.208635 10.4536 0.182266 10.426C0.121972 10.363 0.074751 10.2886 0.0433279 10.2073C0.0119049 10.1259 -0.00309891 10.0391 -0.000817938 9.95196C-0.00367655 9.86472 0.0110314 9.77781 0.0424317 9.69637C0.0738319 9.61494 0.121284 9.54065 0.181966 9.47792C0.208335 9.45095 0.244293 9.43537 0.275456 9.41439L9.91747 0.187683C9.97555 0.128063 10.045 0.0806773 10.1217 0.0483223C10.1983 0.0159673 10.2807 -0.000701904 10.3639 -0.000701904C10.4472 -0.000701904 10.5296 0.0159673 10.6062 0.0483223C10.6829 0.0806773 10.7523 0.128063 10.8104 0.187683C10.9294 0.310634 10.9959 0.475036 10.9959 0.646142C10.9959 0.817247 10.9294 0.981649 10.8104 1.1046L1.53847 9.95226V10.1632L10.8104 18.906C11.0579 19.1568 11.0579 19.5592 10.8104 19.8118C10.7522 19.8712 10.6827 19.9183 10.6061 19.9505C10.5294 19.9827 10.4471 19.9993 10.3639 19.9993C10.2808 19.9993 10.1985 19.9827 10.1218 19.9505C10.0452 19.9183 9.97569 19.8712 9.91747 19.8118L0.275756 10.6909C0.244592 10.6711 0.208635 10.6579 0.182266 10.6304C0.121931 10.5687 0.0746455 10.4956 0.0432389 10.4153C0.0118323 10.3349 -0.00305025 10.2491 -0.000518292 10.1629C-0.00334144 10.0763 0.011398 9.99004 0.0428118 9.90929C0.0742256 9.82854 0.121663 9.75499 0.182266 9.69306C0.208635 9.6643 0.244592 9.65171 0.275756 9.63193" fill="white"/></svg></a>',
					speed: 500,
					fade: true,
					draggable: true,
				}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
					sliderLoader($(this), nextSlide);
				});
			});

			$(window).on('load', function() {
				$('.item-list-gallery.slick-initialized, .item-grid-gallery.slick-initialized').each(function() {
					$(this).slick('refresh');
				});
			});
		});
	};
}(jQuery));
