mtl.alloy.factory['mobile-search'] = (function($) {
    'use strict';

	return function(element, options) {

		$(element).click(function(){

			if($(".header-search-mobile").is(":visible"))
    		{
    			$(".header-search-mobile").slideUp();
    		}
    		else
    		{
				$(".header-search-mobile").slideDown({
				  start: function () {
				    $(this).css({
				      display: "flex"
				    })
				  }
				});
				$(".header-search-query").focus();
    		}
		});
	};
}(jQuery));