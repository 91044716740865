mtl.alloy.factory['testimonial-slider'] = (function($) {
    'use strict';

	return function(element, options) {

		$(document).ready(function() {

		    $('.testimonial-bar').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				autoplaySpeed: 3000,
				speed:700,
		        arrows: false,
		         responsive: [
			        {
			            breakpoint: 9999,
			            settings: "unslick"
			        },
			        {
			            breakpoint: 950,
			             settings: {
								fade: true,
			                }
			        }
			    ]
		    });

		    $(window).on('resize', function() {
		        $('.testimonial-bar').slick('resize');
		    });

		});
	};
}(jQuery));