mtl.alloy.factory['burger-menu'] = (function($) {
    'use strict';

	return function(element, options) {

		$(".header-menu-burger-button").click(function(){
			$(".offset-canvas").stop().slideToggle();

			if($(".offset-canvas").is(":visible"))
			{
				$(element).toggleClass('active');
				$('html, body').toggleClass("noscroll"); 
			}
			else{
				$(element).toggleClass('active');
				$('html, body').toggleClass("noscroll");	
			}
		});
	};
}(jQuery));