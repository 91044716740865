mtl.alloy.factory['homepage-intro'] = (function($) {
    'use strict';

    return function(element, options) {

        if( $(window).width() < 950 )
        {
            var checkHeight = 0;

            $(element).children().each(function () {
                checkHeight += $(this).height();
            });

            var heightChecker = ($(window).width() > 900) ? 300 : 100;

            $(element).css('height', $('.category-information-content p').height() + 10)

            if(checkHeight < heightChecker)
            {
                $(element).toggleClass("fade-content");
                $(element).css("height", 'auto');
                $('.more-information span').hide();
            }
            else
            {
                $(".more-information").click(function(){
                    var $box = $(element);
                    var minimumHeight = $('.category-information-content p').height() + 10;

                    // get current height
                    var currentHeight = $box.innerHeight();

                    // get height with auto applied
                    var autoHeight = $box.css('height', 'auto').innerHeight();

                    // reset height and revert to original if current and auto are equal
                    $box.css('height', currentHeight).animate({
                        height: (currentHeight == autoHeight ? minimumHeight : autoHeight)
                    })

                    $box.toggleClass("fade-content");
                    $(".more-information svg").toggleClass("rotate");

                    if ($box.height() > minimumHeight) {
                        $('.more-information span').text('More Information');
                    } else {
                        $('.more-information span').text('Less Information');
                    }

                });
            }
        }



    };
}(jQuery));