mtl.alloy.factory['more-information'] = (function($) {
    'use strict';

	return function(element, options) {
		
		if( $(window).width() < 950 )
		{
			var checkHeight = 0;

		    $(element).children().each(function () {
				checkHeight += $(this).height();
			});

			var heightChecker = ($(window).width() > 900) ? 300 : 150;

		    if(checkHeight < heightChecker)
		    {
	            $(element).toggleClass("fade-content");
	            $(element).css("height", 'auto');
	            $('.more-information span').hide();
		    }
		    else
		    {	    	
				$(".more-information").click(function(){
			        var $box = $(element);
			        var minimumHeight = 100;
			        
			        // get current height
			        var currentHeight = $box.innerHeight();

			        // get height with auto applied
			        var autoHeight = $box.css('height', 'auto').innerHeight();
			        
			        // reset height and revert to original if current and auto are equal
			        $box.css('height', currentHeight).animate({
			            height: (currentHeight == autoHeight ? minimumHeight : autoHeight)
			        })

		            $box.toggleClass("fade-content");
		            $(".more-information svg").toggleClass("rotate");

			    });
		    }
		}
		else
		{
			var $information = $('.category-information-content'),
				$readMore = $('<a href="#" class="desktop-more-information">More information...</a>'),
				heightCutoff = 85;

			if( $information.height() > heightCutoff )
			{
				$information.addClass('collapsed');
				$readMore.appendTo($information);

				$readMore.on('click', function(e) {
					e.preventDefault();

					$information.toggleClass('collapsed');

					if( $information.hasClass('collapsed') )
					{
						$(this).text('More information...');
					}
					else
					{
						$(this).text('See less');
					}
				});
				
			}
		}
	};
}(jQuery));