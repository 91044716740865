mtl.alloy.factory['homepage-slider'] = (function($) {
    'use strict';

	return function(element, options) {

		$(document).ready(function() {

			$('.title-slider-wrapper').show();

			$('.title-slider').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 3000,
				adaptiveHeight: true,
				nextArrow:'.next',
				prevArrow:'.prev',
				speed:500,
			});
		});
	};
}(jQuery));