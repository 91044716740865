mtl.alloy.factory['delivery-accordion'] = (function($) {
    'use strict';

	return function(element, options) {
		var container = $(element).next();
		var icon = $(element).find('.down-svg');
		var mobileIcon = $(element).find('.down-svg-mobile');

		// hide european delivery
		$('.european-delivery-container').hide();
		$('.european-delivery-accordion').find('.down-svg').css({'transform': 'rotate(0deg)'});
		$('.european-delivery-accordion').find('.down-svg-mobile').css({'transform': 'rotate(0deg)'});

		$('.uk-delivery-accordion').find('.down-svg').css({'transform': 'rotate(-180deg)'});
		$('.uk-delivery-accordion').find('.down-svg-mobile').css({'transform': 'rotate(-180deg)'});


		$(element).click(function(){
		    if($(container).is(":visible"))
    		{
    			$(container).slideUp();
    			$(icon).css({'transform': 'rotate(0deg)', 'transition': '0.5s'});
    			$(mobileIcon).css({'transform': 'rotate(0deg)', 'transition': '0.5s'});
    		}
    		else
    		{
    			$(container).slideDown();
    			$(icon).css({'transform': 'rotate(-180deg)', 'transition': '0.5s'});
    			$(mobileIcon).css({'transform': 'rotate(-180deg)', 'transition': '0.5s'});
    		}
		});
	};
}(jQuery));