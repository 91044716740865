mtl.alloy.factory['stock-status'] = (function($) {
    'use strict';

	return function(element, options) {

		var stock = $(element).data("stock");
		var inStock = $(element).parent().parent().find('.in-stock');
		var outOfStock = $(element).parent().parent().find('.out-of-stock');
		var addToOrder = $(element).parent().parent().next().find('.add-to-order-bottom-inputs');

		if($(element).is(':checked') === true) {
			if(stock == 'in_stock'){
				if($(outOfStock).is(":visible")){
					$(outOfStock).hide();	
					$(addToOrder).show();
				}
				$(inStock).delay(350).show();	
			}

			if(stock == 'out_of_stock'){
				if($(inStock).is(":visible")){
					$(inStock).hide();	
				}
				$(outOfStock).delay(350).show();	
				$(addToOrder).delay(350).hide();
			}
	    }

		$(element).on('change', function(){


			if(stock == 'in_stock'){
				if($(outOfStock).is(":visible")){
					$(outOfStock).fadeOut();	
					$(addToOrder).fadeIn();
				}
				$(inStock).delay(350).fadeIn();	
			}

			if(stock == 'out_of_stock'){
				if($(inStock).is(":visible")){
					$(inStock).fadeOut();	
				}
				$(outOfStock).delay(350).fadeIn();	
				$(addToOrder).delay(350).fadeOut();
			}

		});
	};
}(jQuery));