;(function($, factory) {
  'use strict';

  /**
   * Initialise
   */
  factory['form-validation'] = function(elem, options) {

    var $element = $(elem);
    var $allInputs = $element.find('.checkout-input-text:not([type="hidden"]), .form__textarea, .form__datepicker, select');
    var $submitButton = $element.find('.checkout-button[type="submit"]');
    var $formErrorItems = $element.find('.form__errors__item');

    if ($submitButton.length === 0) {
      var formId = $element.attr('id');
      $submitButton = $('.form__button[form="' + formId + '"]');
    }

    // ---------------------------------------------------------

    // Remove all form validation on page load
    $allInputs.each(function() {
      $(this).removeClass('error');
    });

    // // Validate on form submission
    $submitButton.on('click', function(e) {
      var isValid = true;
      var $formErrors = $element.find('.form__errors');
      $formErrors.html('');

      $allInputs.blur();

      $allInputs.each(function() {
        var $input = $(this);
        if ($input.parent().hasClass('error')) {
          isValid = false;
          if ($input.attr('data-form-error')) {
            $formErrors.append('<div class="form__errors__item">' + $input.attr('data-form-error') + '</div>');
          }
          $formErrors.show();
          $('html, body').animate({
            scrollTop: $formErrors.offset().top
          }, 250);

        }
      });
    });

    // ---------------------------------------------------------

    // Validate on blur/input
    $allInputs.on('blur input', function() {
      var isValid = false;
      var $fields = $([$(this)]);


      $fields.each(function() {
        var $field = $(this);
        var requiresValidation = $field.prop('required') == true
          && !($field.closest('.checkout-input').css('display') === 'none'
            || $field.closest('.checkout-input').hasClass('no-validate'));

        if (!requiresValidation) {
          isValid = true;
          return;
        }

        if ($field.prop('required') == true || $field.is('select')) {
          isValid = notBlank($field);
        }

        if ($field.is('input[type="email"]')) {
          isValid = validateEmail($field.val());
        }

        if ($field.is('input[data-input="email-second"]')) {
          var firstEmail = $element.find('input[data-input="email-first"]').val();
          isValid = validateConfirmationFieldValues(firstEmail, $field.val());
        }

        if ($field.is('input[type="password"]')) {
          isValid = validatePassword($field.val());
        }

        if ($field.is('input[data-input="password-second"]')) {
          var firstPassword = $element.find('input[data-input="password-first"]').val();
          isValid = validateConfirmationFieldValues(firstPassword, $field.val());
        }

        if ($field.is('input[data-mtl-init="datepicker"]')) {
          isValid = isValidDatePickerFormat($field.val());
        }

      });

      // Display validation colours / icons
      $fields.each(function() {
        var $field = $(this);
        if (isValid) {
          $field.removeClass('error').addClass('valid');
        }

        if (!isValid) {
          $field.removeClass('valid').addClass('error');
        }
      });
    });

    // If form errors are displayed after POST, refresh the input validation
    if ($formErrorItems.length > 0) {
      $allInputs.blur();
    }

    // ---------------------------------------------------------

    // Validate field value is not blank
    function notBlank(field) {
      return !(field.val() === '' || field.val() === null);
    }

    // Validate field is numeric
    function isNumber(field) {
      return $.isNumeric(field.val());
    }

    // Validate field is only text
    function isText(field) {
      return field.val().match(/^[a-zA-Z\s]+$/);
    }

    // Validate field length
    function isMinLength(field, minLength) {
      return field.val().length >= minLength;
    }

    // Validate date picker format
    function isValidDatePickerFormat(date) {
      // YYYY-MM-DD
      var regex = new RegExp(/(\d{4})-(\d{2})-(\d{2})/);

      // Date must be in the future
      var enteredDate = new Date(date);
      var currentDate = new Date();
      var isFuture = enteredDate > currentDate;

      return regex.test(date) && isFuture;
    }

    // Validate email address
    function validateEmail(email) {
      var regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      return regex.test(email);
    }

    // Validate password
    function validatePassword(email) {
      var regex = new RegExp(/^(?!\s*$).+/);
      return regex.test(email);
    }

    // Validate confirmation field values match
    function validateConfirmationFieldValues(firstFieldVal, secondFieldVal) {
      return firstFieldVal !== '' && firstFieldVal === secondFieldVal;
    }
  };

}(jQuery, mtl.alloy.factory));
