mtl.alloy.factory['apply-voucher-code'] = (function($) {
    'use strict';

	return function(element, options) {
		var title = $(element).find('.voucher-code-title');
		var container = $(element).find('.voucher-code-fields');
		var icon = $(element).find('.down-svg');

		// hide voucher code box
		container.hide();
		icon.css({'transform': 'rotate(0deg)'});

		$(title).click(function(){
		    if($(container).is(":visible"))
    		{
    			$(container).slideUp();
    			$(icon).css({'transform': 'rotate(0deg)', 'transition': '0.5s'});
    		}
    		else
    		{
    			$(container).slideDown();
    			$(icon).css({'transform': 'rotate(-180deg)', 'transition': '0.5s'});
    		}
		});
	};
}(jQuery));