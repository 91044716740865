mtl.alloy.factory['grid-more-information'] = (function($) {
    'use strict';

	return function(element, options) {

		function checkGridDescriptionSizes() {
			var description = $(element);
			var bullets = null;
			description.find('ul').each(function() {
				var list = $(this);
				if (list.text().trim().length > 0) {
					bullets = list;
					return false;
				}
			});

			if (bullets && bullets.length > 0) {
				var heightBeforeBullets = bullets.offset().top - description.offset().top;
				if (heightBeforeBullets < 20) {
					heightBeforeBullets = (bullets.offset().top + bullets.outerHeight(true)) - description.offset().top;
				}

				var currentHeight = description.height();
				var autoHeight = description.css('height', 'auto').innerHeight();
				description.height(currentHeight);

				var checkHeight = 0;

				description.children().each(function () {
					checkHeight += $(this).height();
				});

				var heightChecker = ($(window).width() > 900) ? 300 : 150;

				if (checkHeight >= heightChecker && autoHeight > heightBeforeBullets) {
					if (!description.hasClass('expanded')) {
						description.height(heightBeforeBullets);
					}
					description.attr('data-min-height', heightBeforeBullets)
						.closest('.items-grid-box').find('.more-information span').show();
				} else {
					description.css("height", 'auto')
						.closest('.items-grid-box').find('.more-information span').hide();
				}
			}
		}
		checkGridDescriptionSizes();

		$(window).resize(checkGridDescriptionSizes);

		$(element).closest('.items-grid-box').find('.more-information').click(function() {
			var $box = $(element);
			var minimumHeight = parseInt($(element).attr('data-min-height'));
			if (isNaN(minimumHeight)) {
				minimumHeight = 100;
			}

			// get current height
			var currentHeight = $box.innerHeight();

			// get height with auto applied
			var autoHeight = $box.css('height', 'auto').innerHeight();

			// reset height and revert to original if current and auto are equal
			var animatingToAuto = currentHeight !== autoHeight;
			$box.css('height', currentHeight).animate({
				height: (animatingToAuto ? autoHeight : minimumHeight)
			}, function() {
				if (animatingToAuto) {
					$box.css('height', 'auto');
				}
			});
			$box.toggleClass('expanded');

			//$box.toggleClass("fade-content");
			$(element).closest('.items-grid-box').find(".more-information svg").toggleClass("rotate");

		});

	};
}(jQuery));