mtl.alloy.factory['mobile-filter'] = (function($) {
    'use strict';

	return function(element, options) {

		$(element).click(function(){
	    	var filter = "."+'filter';
    		$(filter).slideToggle();
		});
	};
}(jQuery));