mtl.alloy.factory['update-delivery'] = (function($) {
    'use strict';

	return function(element, options) {

		var deliveryMethods = $(element).find("input[type='radio'][name='order[delivery_method]']");

		var $orderForm = $('#order_form');

		$(deliveryMethods).change(function(){
			$orderForm.submit();
		});
	};
}(jQuery));