mtl.alloy.factory['update-order'] = (function($) {
    'use strict';

	return function(element, options) {
		var qty = $(element).find('.cell-qty-input');
		var mobileUpdate = $(element).find('#order_save_mobile');
		var update = $(element).find('#order_save');
		var $orderForm = $('#order_form');

		// Move the quantity field:
		if( $(window).width() > 850 )
		{
			$('.order-body-tr').each(function() {
				var  $qty = $(this).find('.cell-qty-input');
				$qty.appendTo(
					$(this).find('.cell-qty.cell-body')
				);
			});
		}

		$(qty).on('input', function(){
			var deleteButton = $(element).find('.cell-delete-link');

			$(deleteButton).fadeOut();
			$(update).add(mobileUpdate).delay(400).fadeIn();

		});

		$(update).add(mobileUpdate).click(function(event){
			$orderForm.submit();
		});
	};
}(jQuery));